import { getRewardValue } from 'utils';
import './style.scss';
import {
  OfferItem,
  SearchInput,
  SeeAllItem,
  ScrollToTop,
  LoadingOffers,
  CustomButton,
  ModalCustom,
  ModalContactAddMerchant
} from 'components';
import { useAppStore } from 'store/appStore';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { getCategories, getCategoriesForAll, getOffersFiltered, searchOffers, clickTracking } from 'services';
import { debounce } from 'lodash';
import { IsearchResult, categoryRecordType } from 'types';
import preIcon from 'assets/images/left-arrow.png';
import nextIcon from 'assets/images/right-arrow.png';
import threedots from 'assets/images/three-dots.png';
import threedotsWhite from 'assets/images/three-dots-white.png';
import { useOutsideClick } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import chevLeft from 'assets/images/chevron-left.svg';
import { trackingEvents } from 'const';
import { osName } from 'react-device-detect';

const limit = 10;

export const Offers = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    setAppLoading,
    setOffersData,
    offersData,
    setCategories,
    categories,
    filteredOffersData,
    setFilteredOffersData,
    category,
    setCategory,
    hasMoreFilterd,
    setHasMoreFilterd,
    appLoading,
    setDataOffers
  } = useAppStore();
  const { user, shadowId } = useAuthStore();

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<IsearchResult[]>([]);
  const [expandFilter, setExpandFilter] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [isSeaching, setIsSearching] = useState(false);
  const [openModaContact, setOpenModaContact] = useState(false);

  const expandBtnRef = useRef(null);
  const categoriesRef = useRef<HTMLDivElement>(null);

  const offerMerchantState = location?.state?.offerMerchantState;
  const isShowMySavings = user?.hasLinkedPlaid || user?.inactiveUserHistory;

  const handleClickOutSide = () => {
    setExpandFilter(false);
  };

  const expandFilterRef = useOutsideClick<HTMLDivElement>(handleClickOutSide, expandBtnRef);

  useEffect(() => {
    const element = document.getElementsByClassName('offers-header--filter-item selected');
    if (element && element[0]) {
      element[0]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, []);

  useEffect(() => {
    if (!categoriesRef.current) return;
    const scrollContainer = categoriesRef.current;
    const tolerance = 2;

    const handleScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;

      setIsAtStart(scrollLeft === 0);

      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - tolerance);
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const platform = `${osName}/WebApp`;
    if (shadowId || user?.userId) {
      clickTracking({
        eventType: trackingEvents.DESKTOP_MERCHANT_OFFER_OVERLAY_LANDING,
        shadowUserId: user?.userId ? user.userId : shadowId || '',
        deviceType: platform,
        eventData: {}
      });
    }
  }, [shadowId, user?.userId]);

  const fetchData = async () => {
    try {
      if (categories?.length > 0 && !!offersData) {
        return;
      }
      setAppLoading(true);
      const resps = await Promise.all([getCategories(), getCategoriesForAll()]);
      const categoriesResp = resps[0];
      const categoriesRespForAll = resps[1];
      setCategories(categoriesResp);
      setCategory(categoriesResp[0] || '');
      setAppLoading(false);

      if (!categoriesResp) {
        return;
      }
      const temp = { ...offersData };
      categoriesRespForAll?.forEach((v) => {
        if (!v.hasOffer || v.displayCategory === 'All') return;
        temp[v.displayCategory] = { data: [], loading: true, category: v.displayCategory };
      });
      setOffersData(temp);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const fetchOffersByCategory = async (c: string) => {
    try {
      const resp = await getOffersFiltered({ limit: 5, category: c });
      const result = { data: resp, loading: false, category: c };
      setDataOffers(c, result);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataFilter = async () => {
    try {
      if ((category && category === categories[0]) || !category) return;
      if (filteredOffersData?.filterBy === category?.displayCategory) return;
      setFilteredOffersData([], filteredOffersData?.filterBy);
      setAppLoading(true);
      const resp = await getOffersFiltered({
        limit: limit,
        category: category?.displayCategory
      });
      if (resp.length < limit) {
        setHasMoreFilterd(false);
      } else {
        setHasMoreFilterd(true);
      }
      setFilteredOffersData(resp, category?.displayCategory);
      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    categories?.forEach((v) => {
      if (!v.hasOffer || v.displayCategory === 'All') return;
      fetchOffersByCategory(v.displayCategory);
    });
  }, [categories]);

  useEffect(() => {
    fetchDataFilter();
  }, [category]);

  useEffect(() => {
    if (offerMerchantState) {
      handleClickOffer(offerMerchantState);
    }
  }, [offerMerchantState]);

  const handleValueSearchChange = (val: string) => {
    setSearchValue(val);
    handleChangeSearchValue(val);
  };

  const handleChangeSearchValue = useCallback(
    debounce(async (val: string) => {
      try {
        if (val?.length < 2) return;
        setIsSearching(true);
        const resp = await searchOffers({ search: val });
        setSearchResults(resp || []);
        setIsSearching(false);
      } catch (err) {
        setIsSearching(false);
        console.log(err);
      }
    }, 500),
    [category]
  );

  const handleClickClear = () => {
    setSearchValue('');
    setSearchResults([]);
  };

  const handleSelectResult = (val: IsearchResult) => {
    console.log('select: ', val);
    handleClickOffer(val.offerMerchant);
  };

  const handleClickSeeAll = (val: string) => {
    const selectedCategory = categories?.find((c) => c.displayCategory.toLowerCase() === val.toLowerCase());
    if (!selectedCategory) return;
    setCategory(selectedCategory);
    setHasMoreFilterd(true);
  };

  const handleClickFilter = (filter: categoryRecordType) => {
    setCategory(filter);
    // if (filteredOffersData?.filterBy !== 'All' && filteredOffersData?.filterBy !== filter?.displayCategory) {
    //   setHasMoreFilterd(true);
    //   console.log('runnn true');
    // }
    setExpandFilter(false);
  };

  const handleLoadMoreOffersFilterd = async () => {
    try {
      if (!category) return;
      setAppLoading(true);
      const offset = filteredOffersData?.data?.length;
      const params = { limit: 30, offset: offset, category: category?.displayCategory };
      const resp = await getOffersFiltered(params);
      if (resp.length < limit) {
        setHasMoreFilterd(false);
      } else {
        setHasMoreFilterd(true);
      }
      const temp = [...filteredOffersData.data, ...resp];
      setFilteredOffersData(temp, category?.displayCategory);
      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const handleClickOffer = async (offerName: string) => {
    navigate(`/offers/details/${offerName}`);
  };

  const handlePreScroll = () => {
    if (categoriesRef.current) {
      categoriesRef.current.scrollTo({
        left: categoriesRef.current.scrollLeft - 200,
        behavior: 'smooth'
      });
    }
  };

  const handleNextScroll = () => {
    if (categoriesRef.current) {
      categoriesRef.current.scrollTo({
        left: categoriesRef.current.scrollLeft + 200,
        behavior: 'smooth'
      });
    }
  };

  const handleExpandFilter = () => {
    setExpandFilter(!expandFilter);
  };

  const handleCloseModalRequestMerchant = () => {
    setOpenModaContact(false);
    handleClickClear();
  };

  const handleClickMySavings = () => {
    navigate('/offers/my-savings');
  };

  const handleBackBtn = () => {
    navigate('/', { replace: true });
  };

  // const nonPremiumOffers = offersData?.filter((e) => e?.displayCategory !== 'Premium');
  const renderCategoryExpanded = useMemo(() => {
    return categories?.filter((ct) => ct?.displayCategory !== 'All' && ct?.displayCategory !== 'Featured');
  }, [categories]);

  return (
    <div className="offers-wrapper">
      <div className="offers-header">
        <button className="offers-header-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <div className="Offers-header-title-container">
          <div className="Offers-header-title-text">
            Personalized Saving Offers{' '}
            <span className="rewards-missed-header-infoIcon" onClick={() => setOpenModal(true)}>
              {/* ⓘ */}
            </span>
          </div>
          {isShowMySavings && (
            <div className="Offers-header-title-btn" onClick={handleClickMySavings}>
              My Savings
            </div>
          )}
        </div>
        <div className="offers-header--search-container">
          <div className="offers-header--search">
            <SearchInput
              value={searchValue}
              onChange={handleValueSearchChange}
              searchResults={searchResults}
              handleSelectResult={handleSelectResult}
              handleClickClear={handleClickClear}
              isSearching={isSeaching}
              handleClickNoResult={() => setOpenModaContact(true)}
            />
          </div>
          <div className="offers-header--filter">
            <div className={`offers-header--action1 ${expandFilter && 'expanded'}`}>
              <img
                src={expandFilter ? threedotsWhite : threedots}
                alt="expand"
                className="offers-header--expand-icon"
                onClick={handleExpandFilter}
                ref={expandBtnRef}
              />
            </div>
            {!expandFilter && (
              <>
                <div className="offers-header--filter-list" ref={categoriesRef}>
                  {categories?.map((ct) => {
                    if (!ct?.hasOffer) {
                      return null;
                    }
                    return (
                      <div
                        className={`offers-header--filter-item ${category?.displayCategory === ct.displayCategory ? 'selected' : ''}`}
                        key={ct.displayCategory}
                        onClick={() => handleClickFilter(ct)}>
                        {ct.displayCategory}
                      </div>
                    );
                  })}
                </div>
                <div className="offers-header--action2">
                  <img
                    src={preIcon}
                    alt="pre"
                    className={`offers-header--pre-icon ${isAtStart ? 'active' : ''}`}
                    onClick={handlePreScroll}
                  />
                  <img
                    src={nextIcon}
                    alt="next"
                    className={`offers-header--next-icon  ${isAtEnd ? 'active' : ''}`}
                    onClick={handleNextScroll}
                  />
                </div>
              </>
            )}
          </div>

          {expandFilter && (
            <div className="offers-header--expand-filter-container" ref={expandFilterRef}>
              {renderCategoryExpanded?.map((ct) => {
                return (
                  <div
                    className="offers-header--expand-filter-item"
                    key={ct.displayCategory}
                    onClick={() => handleClickFilter(ct)}>
                    <img src={ct.blueIcon} alt="category" />
                    <div className="my-savings--expand-filter-item--title">{ct.displayCategory}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="standar-offers-container">
        {categories && category === categories[0] && !!offersData ? (
          <>
            {Object.keys(offersData)?.map((e, idx) => {
              if (e === 'All') return null;
              const offerData = offersData[e];
              const cat = categories?.find((c) => c.displayCategory === offerData?.category);
              if (offerData.loading) {
                return <LoadingOffers key={e} />;
              }
              return (
                <React.Fragment key={idx}>
                  <div className="title-container">
                    <div>
                      <div className="title">
                        {cat?.blueIcon ? <img className="icon" src={cat?.blueIcon} alt="blue-icon" /> : ''}
                        {cat?.displayCategory}
                      </div>
                      {cat?.displayCategory === 'Featured' ? (
                        <div className="subtitle">Based on your recent purchases</div>
                      ) : null}
                    </div>
                    {offerData?.data?.length >= 5 && (
                      <SeeAllItem
                        name={cat?.displayCategory || ''}
                        handleClick={() => handleClickSeeAll(cat?.displayCategory || '')}
                      />
                    )}
                  </div>
                  <div className="offer-container">
                    {offerData?.data?.map((offer, idx) => {
                      return (
                        <OfferItem
                          key={idx}
                          name={offer.offerMerchant}
                          reward={getRewardValue(offer?.offerMerchantRate?.kind, offer?.offerMerchantRate?.amount)}
                          logo={offer.offerMerchantLogoUrl}
                          backgroundLogo={offer.offerMerchantBackgroundUrl}
                          handleClickItem={() => handleClickOffer(offer.offerMerchant)}
                          isPremium={offer?.isOfferMerchantPremium}
                          isBankOffer={offer?.isBankOffer}
                        />
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
          </>
        ) : category ? (
          <>
            <div className="title-container">
              <div className="title">
                {category?.blueIcon ? <img className="icon" src={category?.blueIcon} alt="blue-icon" /> : ''}
                {category?.displayCategory}
              </div>
            </div>
            {filteredOffersData?.data?.length === 0 && appLoading && (
              <div className="standar-offers-container">
                <LoadingOffers isShowTitle={false} />
              </div>
            )}
            <div className="offers-filtered">
              {filteredOffersData?.data?.map((e, idx) => {
                return (
                  <OfferItem
                    key={idx}
                    name={e.offerMerchant}
                    reward={getRewardValue(e?.offerMerchantRate?.kind, e?.offerMerchantRate?.amount)}
                    logo={e.offerMerchantLogoUrl}
                    backgroundLogo={e.offerMerchantBackgroundUrl}
                    handleClickItem={() => handleClickOffer(e.offerMerchant)}
                    isPremium={e?.isOfferMerchantPremium}
                    isBankOffer={e?.isBankOffer}
                  />
                );
              })}
            </div>
            {!appLoading && filteredOffersData?.data?.length === 0 && (
              <div className="coming-soon">
                <h2>Offers coming soon!</h2>
                <p>
                  Currently, there are no available offer in this category.
                  <br /> New offers are continuously loaded - so do check back in some time
                </p>
                <CustomButton className="btn-all-offer" onClick={() => setCategory(categories[0])}>
                  View All Offers
                </CustomButton>
              </div>
            )}
            <div>
              {hasMoreFilterd && filteredOffersData?.data?.length > 0 && (
                <SeeAllItem isFiltered name={'Load more'} handleClick={handleLoadMoreOffersFilterd} />
              )}
            </div>
          </>
        ) : null}
      </div>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        onClose={() => setOpenModal(!openModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Personalized Savings Offers</div>
          <div>
            You can stack savings from these offers in addition to rewards, cashback or any other savings available to
            you.
            <br />
            <br />
            In other words, these offers are sourced directly from merchants to let you save even more on your
            purchases.
            <br />
            <br />
            For example, you may earn 3% cash back on your credit card at Nike, and with 10% back offer - you can
            overall save 13%.
            <br />
            <br />
            Similarly, for $500 spent at Hyatt, you may get 2X points on your credit card, and with $100 back offer -
            you can overall get 500*2= 1,000 points plus $100 back.
            <br />
            <br />
            We are working hard to align these offers based on your spending habits. Feel free to search for any
            merchant, we have 100,000+ stores with more to come.
          </div>
        </div>
        <CustomButton onClick={() => setOpenModal(false)}>Close</CustomButton>
      </ModalCustom>

      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openInfoModal}
        toggle={() => setOpenInfoModal(!openInfoModal)}
        onClose={() => setOpenInfoModal(!openInfoModal)}>
        <h1>Test</h1>
        <CustomButton onClick={() => setOpenInfoModal(false)}>Close</CustomButton>
      </ModalCustom>
      <ScrollToTop />
      <ModalContactAddMerchant isOpen={openModaContact} onClose={handleCloseModalRequestMerchant} />
    </div>
  );
};
