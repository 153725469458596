import { useCallback, useEffect, useRef, useState } from 'react';
import './style.scss';
import { useAppStore } from 'store/appStore';
import {
  clickTracking,
  countBankOffers,
  countStoreOffers,
  createSubscription,
  createSubscriptionWithNoCard,
  getIntentKey,
  getMySubscriptions,
  getPaymentMethods,
  getPlans,
  getStripeConfig,
  getUserSubscriptionConfig,
  reactivateSubscription,
  updatePaymentMethod,
  updatePlan
} from 'services';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  AppLoadingThreedots,
  CustomButton,
  ModalCustom,
  ModalSubscriptionError,
  ModalUpdatePlan,
  PopoverDownloadApp,
  PremiumBenefitItem
} from 'components';
import plusIcon from 'assets/images/plus-icon.png';
import logo from 'assets/images/logo-premium-white.png';
import {
  ACTIVE_STATUS,
  premiumActiveOffers,
  premiumBenefits,
  premiumDigitalCoupons,
  premiumComments,
  unknowErr,
  trackingEvents
} from 'const';
import { PremiumMemberSection } from 'components/PremiumMember/PremiumMemberSection';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSocketSubscription } from 'hooks';
import iconNext from 'assets/images/next.png';
import { useNavigate } from 'react-router-dom';
import preIcon from 'assets/images/back.png';
import nextIcon from 'assets/images/next.png';
import Slider from 'react-slick';
import checkIcon from 'assets/images/uthrive-icon-success.png';
import starRate from 'assets/images/star-filled.png';
import { AppLoadingSuccess } from 'components/AppLoadingSuccess';
import { osName } from 'react-device-detect';
import { useAuthStore } from 'store';

type Props = {
  selectedPlan: string;
  setSelectedPlan: (value: string) => void;
  isCancelledSubscription: boolean;
  setStoreOffer: (value: number) => void;
  setBankOffer: (value: number) => void;
  showModalSubscribe: boolean;
  showModalPlan: boolean;
  openUpdatePlan: boolean;
  isUpdatePayMethod: boolean;
  setOpenModalErr: (value: boolean) => void;
  setShowModalSubscribe: (value: boolean) => void;
  setShowModalPlan: (value: boolean) => void;
  setOpenUpdatePlan: (value: boolean) => void;
  setIsUpdatePayMethod: (value: boolean) => void;
  setLoadThreedots: (value: boolean) => void;
  setLoadSuccess: (value: boolean) => void;
};

const currentDate = moment();

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false
};

export const Premium = () => {
  const navigate = useNavigate();

  const {
    setAppLoading,
    appLoading,
    setPlans,
    setActiveSubscription,
    setSubscriptions,
    setStripeConfig,
    setUserSubscriptionConfig,
    stripeConfig,
    setIntentConfig,
    activeSubscription,
    intentConfig
  } = useAppStore();
  const { user, shadowId } = useAuthStore();
  const [selectedPlan, setSelectedPlan] = useState('');
  const [bankOffer, setBankOffer] = useState(0);
  const [storeOffer, setStoreOffer] = useState(0);
  const [currentIdx, setCurrentIdx] = useState(0);

  const [isShowManagePremium, setIsShowManagePremium] = useState(false);
  const [isShowActivateInAppPremium, setIsShowActivateInAppPremium] = useState(false);

  const [openModalErr, setOpenModalErr] = useState(false);
  const [showModalSubscribe, setShowModalSubscribe] = useState(false);
  const [showModalPlan, setShowModalPlan] = useState(false);
  const [openUpdatePlan, setOpenUpdatePlan] = useState(false);
  const [isUpdatePayMethod, setIsUpdatePayMethod] = useState(false);
  const [loadThreedots, setLoadThreedots] = useState(false);
  const [loadSuccess, setLoadSuccess] = useState(false);
  const activateOfferRef = useRef<Slider>(null);

  const handleChangeSlide = (current: number) => {
    setCurrentIdx(current);
  };

  const handlePreScroll = () => {
    activateOfferRef.current?.slickPrev();
  };

  const handleNextScroll = () => {
    activateOfferRef.current?.slickNext();
  };

  const isEnd = currentIdx === 6;
  const isStart = currentIdx === 0;

  const fetchData = async () => {
    try {
      setAppLoading(true);
      const promiseAll = await Promise.all([
        getStripeConfig(),
        getPlans(),
        getMySubscriptions(),
        getUserSubscriptionConfig(),
        getIntentKey()
      ]);
      const configRes = promiseAll[0];
      const plansRes = promiseAll[1];
      const subscriptionsRes = promiseAll[2];
      const subscriptionConfigRes = promiseAll[3];
      const intentRes = promiseAll[4];
      setIntentConfig(intentRes);
      setUserSubscriptionConfig(subscriptionConfigRes);
      setStripeConfig(configRes);
      setPlans(plansRes);
      setSelectedPlan(plansRes[0].id);
      setSubscriptions(subscriptionsRes);
      const activeSub = subscriptionsRes.find((sub) => ACTIVE_STATUS.indexOf(sub.userSubscriptionStatus) !== -1);
      if (activeSub) {
        setActiveSubscription(activeSub);
        const respCount = await Promise.all([countBankOffers(), countStoreOffers()]);
        setBankOffer(respCount[0]?.data?.totalActivateOffersAvailable || 0);
        setStoreOffer(respCount[1]?.data?.totalMerchantOffersAvailable || 0);

        if (respCount[0]?.data?.totalActivateOffersAvailable || respCount[1]?.data?.totalMerchantOffersAvailable) {
          navigate('/premium/activated-offers', { replace: true });
        }
      }
      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (loadSuccess) {
      setTimeout(() => {
        setLoadSuccess(false);
      }, 1500);
    }
  }, [loadSuccess]);

  useEffect(() => {
    const platform = `${osName}/WebApp`;
    if (shadowId || user?.userId) {
      if (!activeSubscription) {
        clickTracking({
          eventType: trackingEvents.DESKTOP_NON_PREMIUM_EXISTING_USER_SUBSCRIPTION_SCREEN_LANDING,
          shadowUserId: user?.userId ? user.userId : shadowId || '',
          deviceType: platform,
          eventData: {}
        });
      }
    }
  }, [shadowId, user?.userId, activeSubscription]);

  const handleSeeOffers = () => {
    navigate('/premium/activated-offers');
  };

  const handleTryAgain = () => {
    setShowModalSubscribe(true);
    setOpenModalErr(false);
  };

  // if (!stripeConfig?.publishableKey) return null;

  const isCancelledSubscription =
    activeSubscription?.userSubscriptionCanceledAt != null &&
    (currentDate.isBefore(moment(activeSubscription?.userSubscriptionCancelAt)) ||
      currentDate.isSame(moment(activeSubscription?.userSubscriptionCancelAt)));

  const stripePromise = stripeConfig?.publishableKey ? loadStripe(stripeConfig.publishableKey) : undefined;

  return (
    <div className="premium-wrapper">
      {!appLoading ? (
        <>
          {!activeSubscription ? (
            <>
              <div className="premium-header">
                <img src={logo} alt="premium" className="premium-header-img" />
                <div className="premium-header-des">
                  Get <span>$400+</span> automatic cashback on purchases<sup>*</sup>.<br />
                  Give it a try. You can cancel anytime.
                </div>
              </div>
              <IntroContent />
            </>
          ) : bankOffer !== 0 || storeOffer !== 0 ? (
            <div className="premium-see-offers-card-container">
              <div className="premium-see-offers-card" onClick={handleSeeOffers}>
                <div className="premium-see-offers-title">See your offers</div>
                <div className="premium-see-offers-body">
                  <div className="premium-see-offers-values-container">
                    <div className="premium-see-offers-value">
                      <div className="premium-see-offers-amount">{bankOffer}</div>
                      <div className="premium-see-offers-name">Bank Offers</div>
                    </div>
                    <div className="premium-see-offers-value">
                      <div className="premium-see-offers-amount">{storeOffer}</div>
                      <div className="premium-see-offers-name">Store Offers</div>
                    </div>
                  </div>
                  <img src={iconNext} alt="next" />
                </div>
              </div>
            </div>
          ) : (
            <div className="premium-body">
              <div className="premium-active-offers-container">
                <div className="premium-active-offers-title">Turn on all your offers.</div>
                <div className="premium-active-offers-subtitle">
                  Authenticate you bank or store account & you&apos;re done.
                </div>

                <div className="premium-active-offers-list-container">
                  <div
                    className={`premium-active-offers--action left ${isStart ? 'disabled' : ''}`}
                    onClick={handlePreScroll}>
                    <img src={preIcon} alt="pre" />
                  </div>
                  <Slider
                    {...settings}
                    className="premium-active-offers-list"
                    ref={activateOfferRef}
                    afterChange={handleChangeSlide}>
                    {premiumActiveOffers.map((e, idx) => {
                      return <img key={idx} src={e.url} className="premium-active-offer-img" alt="active offers" />;
                    })}
                  </Slider>
                  <div
                    className={`premium-active-offers--action right ${isEnd ? 'disabled' : ''}`}
                    onClick={handleNextScroll}>
                    <img src={nextIcon} alt="next" />
                  </div>
                  <div className="ref-offer">
                    <i>Reference Offers</i>
                  </div>
                </div>
              </div>
            </div>
          )}

          {bankOffer === 0 && storeOffer === 0 && (
            <>
              {activeSubscription && (
                <>
                  <CustomButton
                    onClick={() => setIsShowActivateInAppPremium(!isShowActivateInAppPremium)}
                    id="premium-btn-activate-in-app"
                    className="premium-btn-activate-in-app mt-5">
                    Set up Auto-activate offers in the app
                  </CustomButton>
                  <PopoverDownloadApp
                    title="Auto-activate savings offers in the Uthrive app."
                    isOpen={isShowActivateInAppPremium}
                    target="premium-btn-activate-in-app"
                    handleDone={() => setIsShowActivateInAppPremium(false)}
                    placement="top"
                    modalSize="bg"
                  />
                  <CustomButton
                    className="premium-btn-activate-in-app"
                    id="manage-premium-btn-alt"
                    onClick={() => setIsShowManagePremium(!isShowManagePremium)}>
                    Manage Premium
                  </CustomButton>

                  <PopoverDownloadApp
                    target="manage-premium-btn-alt"
                    title="Manage Premium in the App"
                    isOpen={isShowManagePremium}
                    handleDone={() => setIsShowManagePremium(false)}
                    placement="top"
                  />
                </>
              )}
            </>
          )}
        </>
      ) : null}
      {stripeConfig?.publishableKey && (
        <>
          {intentConfig && !!stripePromise && (
            <Elements
              stripe={stripePromise}
              options={{ clientSecret: intentConfig.setupIntentClientSecret }}
              key={intentConfig.setupIntentClientSecret}>
              <PremiumContent
                setSelectedPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
                isCancelledSubscription={isCancelledSubscription}
                setBankOffer={setBankOffer}
                setStoreOffer={setStoreOffer}
                showModalSubscribe={showModalSubscribe}
                showModalPlan={showModalPlan}
                openUpdatePlan={openUpdatePlan}
                isUpdatePayMethod={isUpdatePayMethod}
                setOpenModalErr={setOpenModalErr}
                setShowModalSubscribe={setShowModalSubscribe}
                setShowModalPlan={setShowModalPlan}
                setOpenUpdatePlan={setOpenUpdatePlan}
                setIsUpdatePayMethod={setIsUpdatePayMethod}
                setLoadThreedots={setLoadThreedots}
                setLoadSuccess={setLoadSuccess}
              />
            </Elements>
          )}
        </>
      )}
      <ModalSubscriptionError
        isOpen={openModalErr}
        handleTryAgain={handleTryAgain}
        onClose={() => setOpenModalErr(false)}
      />
      {loadThreedots && <AppLoadingThreedots />}
      {loadSuccess && <AppLoadingSuccess />}
    </div>
  );
};

const IntroContent = () => {
  const activateOfferRef = useRef<Slider>(null);
  const digitalCouponRef = useRef<Slider>(null);
  const commentRef = useRef<Slider>(null);

  const [currentIdx, setCurrentIdx] = useState(0);
  const [currentCouponIdx, setCurrentCouponIdx] = useState(0);
  const [currentCommentIdx, setCurrentCommentIdx] = useState(0);
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [showAllItems, setShowAllItems] = useState(false);

  const handleChangeSlide = (current: number) => {
    setCurrentIdx(current);
  };
  const handleChangeCouponSlide = (current: number) => {
    setCurrentCouponIdx(current);
  };

  const handleChangeCommentSlide = (current: number) => {
    setCurrentCommentIdx(current);
  };

  const handlePreScroll = () => {
    activateOfferRef.current?.slickPrev();
  };
  const handleNextScroll = () => {
    activateOfferRef.current?.slickNext();
  };

  const handleCouponPreScroll = () => {
    digitalCouponRef.current?.slickPrev();
  };
  const handleCouponNextScroll = () => {
    digitalCouponRef.current?.slickNext();
  };

  const handleCommentPreScroll = () => {
    commentRef.current?.slickPrev();
  };
  const handleCommentNextScroll = () => {
    commentRef.current?.slickNext();
  };

  const handleShowAll = () => {
    setShowAllItems(!showAllItems);
  };

  const isEnd = currentIdx === 6;
  const isStart = currentIdx === 0;

  const isCouponEnd = currentCouponIdx === 4;
  const isCouponStart = currentCouponIdx === 0;

  const isCommentEnd = currentCommentIdx === 1;
  const isCommentStart = currentCommentIdx === 0;

  return (
    <>
      {' '}
      <div className="premium-body">
        <div className="premium-active-offers-container">
          <div className="premium-active-offers-title">Auto-activate Saving Offers on Your Credit or Debit Cards</div>
          <div className="premium-active-offers-subtitle">
            Don&apos;t miss out on offers from your bank - that must be activated to save on purchases, such as{' '}
            <span className="premium-highlight" onClick={() => setOpenCouponModal(true)}>
              these
              <sup>*</sup>
            </span>
            :
          </div>
          <div className="premium-active-offers-list-container">
            <div
              className={`premium-active-offers--action left ${isStart ? 'disabled' : ''}`}
              onClick={handlePreScroll}>
              <img src={preIcon} alt="pre" />
            </div>
            <Slider
              {...settings}
              className="premium-active-offers-list"
              ref={activateOfferRef}
              afterChange={handleChangeSlide}>
              {premiumActiveOffers.map((e, idx) => {
                return <img key={idx} src={e.url} className="premium-active-offer-img" alt="active offers" />;
              })}
            </Slider>
            <div
              className={`premium-active-offers--action right ${isEnd ? 'disabled' : ''}`}
              onClick={handleNextScroll}>
              <img src={nextIcon} alt="next" />
            </div>
            <div className="ref-offer">
              <i>Reference Offers</i>
            </div>
          </div>
        </div>
        <div className="premium-active-offers-plus-icon-container">
          <img src={plusIcon} alt="plus" className="plus-icon" />
        </div>
        <div className="premium-digital-coupons-container">
          <div className="premium-digital-coupons-title">Auto-activate Digital Coupons at Groceries & Pharmacies</div>
          <div className="premium-digital-coupons-subtitle">
            {/* Sign in to your banking/credit card account to
        <br /> activate all offers at once. */}
            No need to manually active digital coupons or carry paper coupons - automatically see savings at checkout at
            stores including{' '}
            <span className="premium-highlight" onClick={() => setOpenCouponModal(true)}>
              these
              <sup>*</sup>
            </span>
            :
          </div>
          <div className="premium-digital-coupons-list-container">
            <div
              className={`premium-digital-coupons--action left ${isCouponStart ? 'disabled' : ''}`}
              onClick={handleCouponPreScroll}>
              <img src={preIcon} alt="pre" />
            </div>
            <Slider
              {...settings}
              className="premium-digital-coupons-list"
              ref={digitalCouponRef}
              afterChange={handleChangeCouponSlide}>
              {premiumDigitalCoupons.map((e, idx) => {
                return (
                  <div className="premium-digital-coupons-item" key={idx}>
                    <div className="premium-digital-coupons-innerItem">
                      <img src={checkIcon} alt="check-icon" className="premium-digital-coupons-chk" />
                      <h3>{e.title}</h3>
                      <img key={idx} src={e.url} className="premium-digital-coupons-img" alt="active offers" />
                    </div>
                  </div>
                );
              })}
            </Slider>
            <div
              className={`premium-digital-coupons--action right ${isCouponEnd ? 'disabled' : ''}`}
              onClick={handleCouponNextScroll}>
              <img src={nextIcon} alt="next" />
            </div>
            {/* <div className="ref-offer">
              <i>Reference Offers</i>
            </div> */}
          </div>
        </div>
        <div className="premium-saving-steps-container">
          <div className="premium-saving-steps-title">Unlock $400+ Real Savings In 3 Simple Steps</div>
          <div>
            <ol>
              <li>Start your free trial for Uthrive Premium</li>
              <li>Auto-activate offer directly from major banks*</li>
              <li>Shop and get cashback in bank account in 7-10 days</li>
            </ol>
          </div>
        </div>
        <div className="premium-comments">
          <div className="premium-comments-container">
            <div className="premium-comments-title">Users like you are loving it!</div>
            <div className="premium-comments-list-container">
              <div
                className={`premium-comments--action left ${isCommentStart ? 'disabled' : ''}`}
                onClick={handleCommentPreScroll}>
                <img src={preIcon} alt="pre" />
              </div>
              <Slider
                {...settings}
                className="premium-comments-list"
                ref={commentRef}
                afterChange={handleChangeCommentSlide}>
                {premiumComments.map((e, idx) => {
                  return (
                    <div className="premium-comments-item" key={idx}>
                      <div className="premium-comments-innerItem">
                        <div className="comment-user d-flex">
                          <span className="comment-user-initial">{e.initial}</span>
                          <div className="comment-user-info">
                            <h3 className="name">{e.name}</h3>
                            <p className="date">{e.days} days ago</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          {[1, 2, 3, 4, 5].map((e, key) => (
                            <img
                              src={starRate}
                              key={key}
                              alt="star rate"
                              width={25}
                              height={25}
                              style={{ marginRight: '8px', marginBottom: '20px' }}
                            />
                          ))}
                        </div>
                        <div style={{ fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: e.desc }} />
                      </div>
                    </div>
                  );
                })}
              </Slider>
              <div
                className={`premium-comments--action right ${isCommentEnd ? 'disabled' : ''}`}
                onClick={handleCommentNextScroll}>
                <img src={nextIcon} alt="next" />
              </div>
              {/* <div className="ref-offer">
              <i>Reference Offers</i>
            </div> */}
            </div>
          </div>
        </div>

        <div className="premium-benefit-container">
          <div className="premium-benefit--title">Maximize Rewards & Savings on Your Purchases</div>
          <div className="premium-benefit-contents">
            {premiumBenefits.map((e, idx) => {
              if (idx === 0) {
                return (
                  <>
                    <PremiumBenefitItem
                      key={idx}
                      title={e.title}
                      img={e.img}
                      desc={e.desc}
                      firstItem={true}
                      showAll={handleShowAll}
                      isDisplay={showAllItems}
                    />
                  </>
                );
              } else {
                return (
                  <>
                    {showAllItems && (
                      <PremiumBenefitItem key={idx} title={e.title} img={e.img} desc={e.desc} firstItem={false} />
                    )}
                  </>
                );
              }
            })}
          </div>
          {showAllItems && (
            <div className="premium-benefit--subtitle">
              <sup>*</sup> You&apos;ll get automatic statement credits on your credit card when you shop at merchants,
              you will also get automatic discounts/cashback on your purchases at stores{' '}
              <span className="premium-highlight" onClick={() => setOpenCouponModal(true)}>
                see details
              </span>
              .
            </div>
          )}
        </div>
      </div>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openCouponModal}
        toggle={() => setOpenCouponModal(!openCouponModal)}
        onClose={() => setOpenCouponModal(!openCouponModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Auto-activate Savings Offers & Digital Coupons</div>
          <div>
            *You&apos;ll get statement credits when you shop at merchants with your credit cards or debit cards for
            which available offers have been activated, subject to other offer terms. <br />
            <br />
            Some merchants may give additional rewards such as points, miles etc. instead of statement credits on
            qualifying activity. Offer value provided by your bank/issuer and typically appear on your online account
            within 10-14 days after qualifying activity. <br />
            <br />
            Auto-activate offers currently available on American Express, Bank of America, Chase, Citi, Wells Fargo and
            US Bank. More banks will be added shortly. <br />
            <br />
            Auto-activate Digital Coupons currently available on Target, Walgreens, Rite Aid, Publix, Shoprite, Sprouts
            & BJ&apos;s. More stores will be added shortly.
          </div>
        </div>
        <CustomButton onClick={() => setOpenCouponModal(!openCouponModal)}>Close</CustomButton>
      </ModalCustom>
    </>
  );
};

const PremiumContent = ({
  selectedPlan,
  setSelectedPlan,
  isCancelledSubscription,
  setBankOffer,
  setStoreOffer,
  showModalSubscribe,
  showModalPlan,
  openUpdatePlan,
  isUpdatePayMethod,
  setOpenModalErr,
  setShowModalSubscribe,
  setShowModalPlan,
  setOpenUpdatePlan,
  setIsUpdatePayMethod,
  setLoadThreedots,
  setLoadSuccess
}: Props) => {
  const navigate = useNavigate();
  const {
    subscriptions,
    plans,
    userSubscriptionConfig,
    setAppLoading,
    intentConfig,
    setSubscriptions,
    setActiveSubscription,
    activeSubscription,
    setIntentConfig
  } = useAppStore();
  const { user, shadowId } = useAuthStore();
  const timeOut = useRef<NodeJS.Timeout | null>(null);
  const callbackRef = useRef<((data: any) => void) | undefined>(undefined);

  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit = async () => {
    if (!stripe || !elements || !intentConfig) {
      return;
    }
    // setAppLoading(true);
    setLoadThreedots(true);
    const resp = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: ''
      },
      redirect: 'if_required'
    });

    if (resp.error) {
      toast.error(resp.error.message);
      // setAppLoading(false);
      setLoadThreedots(false);
      return;
    }

    if (!timeOut.current) {
      timeOut.current = setTimeout(async () => {
        console.log('run time out');
        const paymentMethods = await getPaymentMethods();
        const plan = plans.find((p) => p.id === selectedPlan);
        if (plan && paymentMethods[0].linkedPaymentMethodId === resp.setupIntent?.payment_method) {
          const data = {
            userSubscriptionPriceId: plan.prices[0].id || '',
            userSubscriptionPaymentMethodId: paymentMethods[0].id || ''
          };
          try {
            if (isUpdatePayMethod) {
              if (!activeSubscription?.id) return;
              const dataUpdate = {
                userSubscriptionPaymentMethodId: paymentMethods[0].id || '',
                subscriptionId: activeSubscription?.id
              };
              await updatePaymentMethod(dataUpdate);
              setIsUpdatePayMethod(false);
              // toast.success('Updated payment method successfully');
              // setLoadSuccess(true);
            } else {
              await createSubscription(data);
              // toast.success('Created subscription successfully');
              // setLoadSuccess(true);
            }

            subscribeSuccessCallback();
            // setAppLoading(false);
            // setLoadThreedots(false);
          } catch (error: any) {
            setOpenModalErr(true);
            setShowModalSubscribe(false);
            if (timeOut.current) clearTimeout(timeOut.current);
            const newIntentKey = await getIntentKey();
            setIntentConfig(newIntentKey);
            // setAppLoading(false);
            setLoadThreedots(false);
          }
        }
      }, 12000);
    }

    console.log('rest: ', resp);
  };

  const handleCreateSubscription = async (paymentMethodsId: string) => {
    try {
      const plan = plans.find((p) => p.id === selectedPlan);
      if (!plan) return;
      const data = {
        userSubscriptionPriceId: plan.prices[0].id || '',
        userSubscriptionPaymentMethodId: paymentMethodsId || ''
      };
      await createSubscription(data);
      subscribeSuccessCallback();
      // setAppLoading(false);
      // setLoadThreedots(false);
      // toast.success('Created subscription successfully');
      // setLoadSuccess(true);
    } catch (error: any) {
      console.log('error: ', error);
      setShowModalSubscribe(false);
      setOpenModalErr(true);
      if (timeOut.current) clearTimeout(timeOut.current);
      const newIntentKey = await getIntentKey();
      setIntentConfig(newIntentKey);
      // setAppLoading(false);
      setLoadThreedots(false);
    }
  };

  const handleClickUpdatePlan = async () => {
    setOpenUpdatePlan(true);
  };

  const handleUpdatePlan = async () => {
    if (!activeSubscription?.id || !selectedPlan) return;
    try {
      const plan = plans.find((p) => p.id === selectedPlan);
      if (!plan) return;
      setAppLoading(true);
      await updatePlan({ subscriptionId: activeSubscription?.id, userSubscriptionPriceId: plan?.prices[0].id });
      const subscriptionsRes = await getMySubscriptions();
      const activeSub = subscriptionsRes.find((sub) => ACTIVE_STATUS.indexOf(sub.userSubscriptionStatus) !== -1);
      if (activeSub) setActiveSubscription(activeSub);
      setAppLoading(false);
      setOpenUpdatePlan(false);
      toast.success('Updated plan successfully');
    } catch (err) {
      console.log(err);
      setAppLoading(false);
      setOpenUpdatePlan(false);
      toast.error('Update plan failed');
    }
  };

  const handleUpdatePaymentMethod = async (paymentMethodsId: string) => {
    if (!activeSubscription?.id || !paymentMethodsId) return;
    try {
      const data = {
        subscriptionId: activeSubscription?.id,
        userSubscriptionPaymentMethodId: paymentMethodsId || ''
      };
      await updatePaymentMethod(data);
      setIsUpdatePayMethod(false);
      subscribeSuccessCallback();
      // setAppLoading(false);
      // setLoadThreedots(false);
      // toast.success('Updated payment method successfully');
    } catch (e) {
      console.log('error: ', e);
      setShowModalSubscribe(false);
      setOpenModalErr(true);
      const newIntentKey = await getIntentKey();
      setIntentConfig(newIntentKey);
      // setAppLoading(false);
      setLoadThreedots(false);
    }
  };

  const updateCallback = useCallback(
    (data: any) => {
      console.log('run socket subscription');
      const userPaymentMethodId = data?.data?.userPaymentMethodId;
      if (isUpdatePayMethod) {
        handleUpdatePaymentMethod(userPaymentMethodId);
      } else {
        handleCreateSubscription(userPaymentMethodId);
      }
    },
    [isUpdatePayMethod, handleUpdatePaymentMethod, handleCreateSubscription]
  );

  useEffect(() => {
    callbackRef.current = updateCallback;
  }, [updateCallback]);

  useSocketSubscription((data) => {
    if (callbackRef.current) {
      callbackRef.current(data);
    }
  });

  const subscribeSuccessCallback = async () => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
      timeOut.current = null;
    }
    onCloseModal();
    callback();
  };

  const callback = async () => {
    console.log('run call back');
    // setAppLoading(true);
    setLoadThreedots(true);
    const respCount = await Promise.all([countBankOffers(), countStoreOffers()]);
    setBankOffer(respCount[0]?.data?.totalActivateOffersAvailable || 0);
    setStoreOffer(respCount[1]?.data?.totalMerchantOffersAvailable || 0);

    let subscriptionsRes = await getMySubscriptions();
    let activeSub = subscriptionsRes.find((sub) => ACTIVE_STATUS.indexOf(sub.userSubscriptionStatus) !== -1);
    while (!activeSub) {
      subscriptionsRes = await getMySubscriptions();
      activeSub = subscriptionsRes.find((sub) => ACTIVE_STATUS.indexOf(sub.userSubscriptionStatus) !== -1);
    }
    setSubscriptions(subscriptionsRes);

    if (activeSub) setActiveSubscription(activeSub);
    setLoadSuccess(true);
    setLoadThreedots(false);
    if (respCount[0]?.data?.totalActivateOffersAvailable || respCount[1]?.data?.totalMerchantOffersAvailable) {
      navigate('/premium/activated-offers', { replace: true });
    }
    // setAppLoading(false);
  };
  const onCloseModal = () => {
    setShowModalSubscribe(false);
    setIsUpdatePayMethod(false);
  };

  const handleReactivatePremium = async () => {
    try {
      if (!subscriptions) return;
      setAppLoading(true);
      await reactivateSubscription({ subscriptionId: subscriptions[0]?.id });
      const subscriptionsRes = await getMySubscriptions();
      setSubscriptions(subscriptionsRes);
      const activeSub = subscriptionsRes.find((sub) => ACTIVE_STATUS.indexOf(sub.userSubscriptionStatus) !== -1);
      if (activeSub) setActiveSubscription(activeSub);
      setAppLoading(false);
      toast.success('Reactivated premium successfully');
    } catch (err) {
      console.log('err: ', err);
      setAppLoading(false);
      toast.error('Reactivated premium failed');
    }
  };

  const handleConfirmPlan = () => {
    if (selectedPlan) {
      setShowModalPlan(false);
      setShowModalSubscribe(true);
    }
  };

  const handleNoCard = async () => {
    try {
      setAppLoading(true);
      const plan = plans.find((p) => p.id === selectedPlan);
      const _tmp = {
        userSubscriptionPriceId: plan?.prices?.[0]?.id || '',
        userSubscriptionPromotionCode: null,
        userSubscriptionCollectionMethod: 'charge_automatically',
        userSubscriptionCancelAtPeriodEnd: 'N',
        userSubscriptionDescription: 'create user subscription no trial no promotion',
        userSubscriptionAutomaticTax: 'N',
        userSubscriptionTrialEnd: null,
        userSubscriptionApplicationFeePercent: null,
        userSubscriptionBillingCycleAnchor: null
      };
      await createSubscriptionWithNoCard(_tmp);
      setAppLoading(false);
      setShowModalPlan(false);
      callback();
    } catch (err) {
      console.log(err);
      setAppLoading(false);
      toast.error(unknowErr);
    }
  };

  const handleClickGetOffer = async () => {
    console.log('passed');
    const platform = `${osName}/WebApp`;
    const confirm = await clickTracking({
      eventType: trackingEvents.DESKTOP_NON_PREMIUM_EXISTING_USER_INIT_SUBSCRIPTION_CLICK,
      shadowUserId: user?.userId ? user.userId : shadowId || '',
      deviceType: platform,
      eventData: {}
    });
    if (confirm) {
      console.log('Confirmed Tracking');
    }
  };

  const isTrialAvailable = subscriptions.length === 0;
  return (
    <div>
      {!!subscriptions && subscriptions?.length > 0 && (
        <div className="manage-subscription-container d-none">
          <div className="manage-subscription-title">Manage Subscription</div>
          {activeSubscription && !isCancelledSubscription ? (
            <div className="manage-subscription-body">
              <div className="manage-subscription-card">
                <div className="manage-subscription-card--title">{`You are subscribed to a ${activeSubscription?.userSubscriptionPrice?.priceProduct?.productName?.toLowerCase()}`}</div>
                <div className="manage-subscription-card--body"></div>
                <div className="manage-subscription-card--btn" onClick={handleClickUpdatePlan}>
                  Update Plan
                </div>
              </div>
              <div className="manage-subscription-card">
                <div className="manage-subscription-card--title">Your Payment</div>
                <div className="manage-subscription-card--body">
                  <div>
                    Your next bill is on{' '}
                    {`${moment(activeSubscription.upcomingInvoice?.nextRenewalDate).format('DD/MM/YYYY')}`} for the
                    amount of ${`${activeSubscription.upcomingInvoice?.amount}`} + tax.
                  </div>
                  <div className="manage-subscription-card--branch-container">
                    <img
                      className="manage-subscription-card--branch-logo"
                      src={activeSubscription.userSubscriptionPaymentMethod?.cardInfo?.brandLogo}
                      alt="branch"
                    />
                    <div className="manage-subscription-card--branch-info">
                      <div>{`${activeSubscription.userSubscriptionPaymentMethod?.cardInfo?.display_brand} ending in ${activeSubscription.userSubscriptionPaymentMethod?.cardInfo?.last4}`}</div>
                      <div>
                        Expires:{' '}
                        {`${activeSubscription.userSubscriptionPaymentMethod?.cardInfo?.exp_month}/${activeSubscription.userSubscriptionPaymentMethod?.cardInfo?.exp_year}`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="manage-subscription-card--btn" onClick={() => setIsUpdatePayMethod(true)}>
                  Update Payment Method
                </div>
              </div>
            </div>
          ) : (
            <div className="cancelled-text">{`You’ve cancelled your membership on ${moment(subscriptions[0]?.userSubscriptionCanceledAt).format('DD/MM/YYYY')}. `}</div>
          )}
        </div>
      )}
      <ModalCustom
        isOpen={showModalSubscribe || isUpdatePayMethod}
        className="modal-blur modal-lg modal-login-account"
        toggle={onCloseModal}
        onClose={onCloseModal}
        isSupportClose>
        <div className="subscribe-form">
          <div className="subscribe-form-title">
            {`Special Offer: We're giving ${userSubscriptionConfig?.trialPeriod + '-' + userSubscriptionConfig?.trialPeriodTimeUnit} Trial for only`}{' '}
            <span>${userSubscriptionConfig?.trialFee || 0}</span>!
          </div>
          <div className="payment-method-title">Payment</div>
          <div className="subscribe-form-subtitle">All transactions are secure and encrypted.</div>
          <div></div>
          <div className="payment-form-wrapper ">
            <PaymentElement options={{ layout: 'tabs' }} />
          </div>
          <CustomButton className="submit-button" onClick={() => handleSubmit()}>
            {isUpdatePayMethod ? 'Update' : 'Pay Now'}
          </CustomButton>
        </div>
      </ModalCustom>
      <div className="flex" style={{ width: '100%', textAlign: 'center', paddingTop: 40 }}>
        {activeSubscription && !isCancelledSubscription ? (
          <PremiumMemberSection callback={callback} />
        ) : isCancelledSubscription ? (
          <CustomButton className="premium-btn d-none" onClick={handleReactivatePremium}>
            Reactivate Premium
          </CustomButton>
        ) : (
          <CustomButton
            className="premium-btn"
            onClick={() => {
              handleClickGetOffer();
              setShowModalPlan(true);
            }}>
            {isTrialAvailable
              ? `Special Offer: ${userSubscriptionConfig?.trialPeriod}-${userSubscriptionConfig?.trialPeriodTimeUnit} Trial`
              : 'Sign up'}
          </CustomButton>
        )}
      </div>
      <ModalUpdatePlan
        isOpen={showModalPlan}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        isTrialAvailable={subscriptions.length === 0}
        userSubscriptionConfig={userSubscriptionConfig}
        toggle={() => setShowModalPlan(false)}
        plans={plans}
        handleSubmit={handleConfirmPlan}
        activeSubscription={activeSubscription}
        isUpdateModal={false}
        isNewUser={!isCancelledSubscription}
        handleNoCard={handleNoCard}
      />
      <ModalUpdatePlan
        isOpen={openUpdatePlan}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        isTrialAvailable={subscriptions.length === 0}
        userSubscriptionConfig={userSubscriptionConfig}
        toggle={() => setOpenUpdatePlan(false)}
        plans={plans}
        handleSubmit={handleUpdatePlan}
        activeSubscription={activeSubscription}
        isUpdateModal
      />
    </div>
  );
};
