export const trackingEvents = {
  DESKTOP_EXTN_UNINSTALL_FEEDBACK_LANDING: 'DESKTOP_EXTN_UNINSTALL_FEEDBACK_LANDING',
  DESKTOP_EXTN_UNINSTALL_REINSTALL_CLICK: 'DESKTOP_EXTN_UNINSTALL_REINSTALL_CLICK',
  DESKTOP_EXTN_UNINSTALL_OPTION_CLICK: 'DESKTOP_EXTN_UNINSTALL_OPTION_CLICK',
  DESKTOP_EXTN_UNINSTALL_WRITE_INPUT_CLICK: 'DESKTOP_EXTN_UNINSTALL_WRITE_INPUT_CLICK',
  DESKTOP_EXTN_UNINSTALL_SUBMIT_CLICK: 'DESKTOP_EXTN_UNINSTALL_SUBMIT_CLICK',
  DESKTOP_MERCH_OFFER_GET_OFFER_CLICK: 'DESKTOP_MERCH_OFFER_GET_OFFER_CLICK',
  DESKTOP_MERCHANT_OFFER_OVERLAY_LANDING: 'MERCHANT_OFFER_OVERLAY_LANDING',
  DESKTOP_MERCHANT_OFFER_DETAILS_LANDING: 'DESKTOP_MERCHANT_OFFER_DETAILS_LANDING',
  DESKTOP_COMPARE_REWARDS_GET_OFFER_CLICK: 'DESKTOP_COMPARE_REWARDS_GET_OFFER_CLICK',
  CONFIRM_ACCOUNT_VERIFICATION: 'CONFIRM_ACCOUNT_VERIFICATION',
  DESKTOP_NON_PREMIUM_EXISTING_USER_SUBSCRIPTION_SCREEN_LANDING:
    'DESKTOP_NON_PREMIUM_EXISTING_USER_SUBSCRIPTION_SCREEN_LANDING',
  DESKTOP_NON_PREMIUM_EXISTING_USER_INIT_SUBSCRIPTION_CLICK: 'DESKTOP_NON_PREMIUM_EXISTING_USER_INIT_SUBSCRIPTION_CLICK'
};
