import { Fragment, useCallback, useEffect, useState } from 'react';
import './style.scss';
import { clickTracking, compareRewards, getOffersMerchantDetails } from 'services';
import { useAppStore, useAuthStore } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CompareCardType, IOfferMerchantDetails } from 'types';
import CurrencyInput from 'react-currency-input-field';
import { debounce } from 'lodash';
import tickIcon from 'assets/images/tick.jpg';
import { fixMoney, getRewardValue } from 'utils';
import chevLeft from 'assets/images/chevron-left.svg';
import checkIcon from 'assets/images/green-check-icon.svg';
import refreshIcon from 'assets/images/refresh.png';
import { CustomButton, ModalCustom, ModalWFOfferTerms, OfferHowItWork } from 'components';
import { osName } from 'react-device-detect';
import { trackingEvents } from 'const';

const CompareCard = ({
  data,
  hasWFOffer,
  merchantName,
  merchantCategory
}: {
  data: CompareCardType;
  hasWFOffer: boolean;
  merchantName: string;
  merchantCategory: string;
}) => {
  const { user, shadowId } = useAuthStore();

  const [isOpenTC, setIsOpenTC] = useState(false);
  const [isOpenModalWF, setIsOpenModalWF] = useState(false);

  const rewardOffer = data?.offers?.find((offer) => offer?.offerType === 'reward');
  const cashbackOffer = data?.offers?.find((offer) => offer?.offerType === 'cashback');
  const bankOffer = data?.offers?.find((offer) => offer?.offerType === 'bank_offer');

  const handleGetOffer = async () => {
    console.log('passed');
    const platform = `${osName}/WebApp`;
    const offerValue = cashbackOffer?.offerDescription;
    const confirm = await clickTracking({
      eventType: trackingEvents.DESKTOP_COMPARE_REWARDS_GET_OFFER_CLICK,
      shadowUserId: user?.userId ? user.userId : shadowId || '',
      deviceType: platform,
      eventData: {
        merchant_name: merchantName,
        offer_value: offerValue,
        merchant_category: merchantCategory
      }
    });
    if (confirm) {
      console.log('Confirmed Tracking');
    }
    window.open(cashbackOffer?.offerMerchantAffiliateUrl, '_blank');
  };

  return (
    <div className="compare-item">
      <div className="compare-card-container">
        <div className="compare-card-info">
          <div className="compare-card-title">{`${data?.cardNameFull} ${data.accountMask ? '-' + data.accountMask : ''}`}</div>
          <div className="compare-card-info-container">
            <img src={data.cardImageUrl} alt="card" />
            <div>
              <div className="compare-card-info-text">Reward you earn</div>
              <div className="compare-card-info-reward">${fixMoney(rewardOffer?.rewardAmount || 0)}</div>
            </div>
          </div>
        </div>

        <div className={`compare-card-offers-container ${!hasWFOffer ? 'bg-white' : ''}`}>
          {hasWFOffer && (
            <>
              <div className="compare-card-offers-tag">+ Additional Savings with Offers</div>
              <div className="compare-card-offers-rewards">
                <div className="compare-card-offers-rewards-desc">{cashbackOffer?.offerShortDesc}</div>
                <div className="compare-card-offers-rewards-amount">${fixMoney(cashbackOffer?.rewardAmount || 0)}</div>
              </div>
              <OfferHowItWork />
              <div className="compare-card--tc-btn" onClick={() => setIsOpenModalWF(!isOpenModalWF)}>
                T&C Apply
              </div>
              {bankOffer && <div className="devider" />}
            </>
          )}
          {bankOffer && (
            <div>
              <div className="compare-card-premium-container mb-2">
                <div className="compare-card-premium">
                  <img src={tickIcon} alt="premium" className="icon-check" />
                  <div className="compare-card-premium-text">Premium Offer Activated</div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="compare-card-bank-offer-desc">{bankOffer.offerShortDesc}</div>
                <div className="compare-card-premium-amount">${fixMoney(bankOffer.rewardAmount)}</div>
              </div>
              <div className="compare-card--tc-btn" onClick={() => setIsOpenTC(!isOpenTC)}>
                T&C Apply
              </div>
              {bankOffer?.isQualify !== true && (
                <div className="compare-card-bank-offer-error mt-1">{bankOffer?.errorDescription}</div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="compare-card--total-savings">
        <img src={checkIcon} alt="check" />
        <div>Total Savings: ${fixMoney(data?.totalRewardAmount)}</div>
      </div>
      {hasWFOffer && (
        <CustomButton className="compare-card--get-offer-btn" onClick={handleGetOffer}>
          Get offers
        </CustomButton>
      )}
      <ModalCustom
        isOpen={isOpenTC}
        className="custom-modal-offer-tc"
        toggle={() => setIsOpenTC(!isOpenTC)}
        onClose={() => setIsOpenTC(!isOpenTC)}
        isSupportClose>
        <div className="offerDescription-container">{bankOffer?.offerDescription}</div>
      </ModalCustom>
      <ModalWFOfferTerms isOpen={isOpenModalWF} onClose={() => setIsOpenModalWF(false)} merchantName={merchantName} />
    </div>
  );
};

export const CompareRewards = () => {
  const params = useParams<{ merchantName: string }>();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  console.log(params);
  const merchantName = params?.merchantName || '';
  const { setAppLoading } = useAppStore();

  const [cards, setCards] = useState<CompareCardType[] | null>([]);
  const [amount, setAmount] = useState<string | undefined>('');
  const [offerMerchant, setOfferMerchant] = useState<IOfferMerchantDetails | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    fetchMerchantDetail();
  }, [merchantName]);

  const fetchMerchantDetail = async () => {
    try {
      setAppLoading(true);
      const params = { merchantName: merchantName };
      const resp = await getOffersMerchantDetails(params);
      setOfferMerchant(resp);
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleBackBtn = () => {
    navigate('/offers', { replace: true });
  };

  const fetchCards = async (value: string | undefined) => {
    try {
      if (!merchantName || (value !== '0' && !value)) return;
      setAppLoading(true);
      const params = { merchantName: merchantName, spendAmount: value };
      const resp = await compareRewards(params);
      setCards(resp?.data);
      setAppLoading(false);
    } catch (err: any) {
      console.log(err);
      setAppLoading(false);
      toast.error(err?.response?.data?.message || 'Something went wrong');
      navigate('/cards', { replace: true });
    }
  };

  const handleChangeAmount = useCallback(
    debounce(async (val: string | undefined) => {
      try {
        await fetchCards(val);
        if (val && parseInt(val) > 100) setIsOpen(true);
      } catch (err) {
        console.log(err);
      }
    }, 500),
    []
  );

  const handleChange = useCallback(
    (value: string | undefined) => {
      setIsOpen(false);
      if (value === amount) return;
      setAmount(value);
      handleChangeAmount(value);
    },
    [amount]
  );

  const handleRefresh = () => {
    setAmount('');
    setCards([]);
  };

  if (!merchantName) return <div className="compare-rewards-container"></div>;

  return (
    <div className="compare-rewards-container">
      <button className="compare-rewards-btnBack" onClick={handleBackBtn}>
        <img src={chevLeft} alt="chevron-left" />
        Back
      </button>
      <div className="compare-rewards-header">
        <div className="compare-rewards-header--title">Compare rewards on all your cards</div>
        {!!offerMerchant && (
          <div className="compare-rewards-header--merchant-container">
            <img
              src={offerMerchant?.merchant?.merchantImgUrl || offerMerchant?.merchant?.categoryImgUrl}
              alt="merchant"
            />
            <div>Simply tell us how much you plan to spend?</div>
          </div>
        )}
        <div className="compare-rewards-header--merchant-name">
          {offerMerchant?.merchant.offerMerchant || merchantName}
        </div>
        <div className="compare-rewards-header--input">
          <div className="compare-rewards-header--input-container">
            <CurrencyInput prefix="$" placeholder="$0" decimalsLimit={2} value={amount} onValueChange={handleChange} />
            {!!amount && <img src={refreshIcon} alt="refresh" onClick={handleRefresh} />}
          </div>
          <div className="compare-rewards-header--input-lable">Enter amount</div>
        </div>
      </div>
      <div className="compare-rewards-body">
        <div className="compare-rewards-contents">
          {cards?.map((c, idx) => {
            if (idx === 0) {
              return (
                <Fragment key={idx}>
                  <div className="tag tag-yellow">Best card for {merchantName}</div>
                  <CompareCard
                    data={c}
                    hasWFOffer={!!offerMerchant?.merchant.offerMerchantRate}
                    merchantName={merchantName}
                    merchantCategory={offerMerchant?.merchant?.mappedCategory || ''}
                  />
                </Fragment>
              );
            } else if (idx === 1) {
              return (
                <Fragment key={idx}>
                  <div className="tag">Other cards you own</div>
                  <CompareCard
                    data={c}
                    hasWFOffer={!!offerMerchant?.merchant.offerMerchantRate}
                    merchantName={merchantName}
                    merchantCategory={offerMerchant?.merchant?.mappedCategory || ''}
                  />
                </Fragment>
              );
            }
            return (
              <CompareCard
                data={c}
                key={idx}
                hasWFOffer={!!offerMerchant?.merchant.offerMerchantRate}
                merchantName={merchantName}
                merchantCategory={offerMerchant?.merchant?.mappedCategory || ''}
              />
            );
          })}

          {isOpen && (
            <div className={`compare-suggestion ${isOpen ? 'active' : 'inactive'}`}>
              <div className="compare-suggestion-topModal">
                <span className="tag">New</span>
                <span className="close" onClick={() => setIsOpen(!isOpen)}>
                  ×
                </span>
              </div>
              <div className="compare-suggestion-bodyModal">
                <h3>
                  <span className="highlight">{user?.firstName || 'Hi there'}</span>, Looking to spend ${amount} at{' '}
                  {offerMerchant?.merchant.offerMerchant}?
                </h3>
                <div className="desc">
                  <p>Check out our new recommended cards for you. You will:</p>
                  <ul>
                    <li>
                      Up to 100,000 points or $900 cash back as welcome offer by fully or partly qualifying with this
                      spend
                    </li>
                    <li>Also get all the rewards + savings</li>
                  </ul>
                </div>

                <CustomButton
                  onClick={() => {
                    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                    navigate('/cards/');
                  }}>
                  View recommended cards
                </CustomButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
